import React, { useState, useEffect } from 'react';
import { Drawer, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as apiClient from '../../common/apiClient';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import { useNotify } from "react-admin";
import "./report.css";
// import { ICandidate, IHeader } from '../../dashboard/Dashboard';
import LatestCandidates from '../../dashboard/LatestCandidates';
import exportFromJSON from 'export-from-json'
import {
    Edit,
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    required,
    BooleanInput,
    ImageInput,
    ImageField, DateInput, AutocompleteInput
} from "react-admin";
import { CandidateView } from './CandidateView';
import { CandidateEmail } from './CandidateEmail';
import workauthorizations from '../workauthorizations';
const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: 600,
    },
    drawerPaper1500: {
        zIndex: 100,
        marginTop: 50,
        width: 1300,
    },
}));
export const CandidateQuery = (props: any) => {
    const [roletypeid, setRoletypeid] = useState<any>(0);
    const [uid, setUid] = useState(0);
    // const [header, setHeader] = useState<IHeader>();
    const [candidates, setCandidates] = useState<any>([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [filterCondition, setFilterCondition] = useState<any>("");
    const [db, setDB] = useState([]);
    const [isCandidateProgress, setCandidateProgress] = useState<any>(false);
    const [showAdvancedFilters, setShowAdvancedFilters] = useState<any>(false);
    const [selectedRecord, setSelectedRecord] = useState<any>(null);

    const [workStatus, setWorkStatus] = useState<any>(null);
    const [location, setLocation] = useState<any>(null);
    const [stateId, setStateId] = useState<any>(null);
    
    const [topSkill, setTopSkill] = useState<any>(null);
    const [secondarySkill, setSecondarySkill] = useState<any>(null);
    const [experience, setExperience] = useState<any>(null);
    const [jobTitleId, setJobTitleId] = useState<any>(null);
    
    const classes = useStyles({});

    const notify = useNotify();
    const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const myclasses = myStyles({});
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenEmail, setIsOpenEmail] = useState(false);

    let userId: any = localStorage.getItem("userId");
    if (userId) {
        userId = parseInt(userId);
    }
    let Ownrecords: any = localStorage.getItem("Ownrecords");
    Ownrecords = Ownrecords === "true";

    const loadMoreCandidates = () => {
        setLoading(true);
        let data: any = {
            UserID: uid, RoleTypeID: roletypeid, FilterCondition: filterCondition
            , StartIndex: pageIndex
            , PageSize: pageSize
            , cityId:(location==null|| location=="")?null:location
            , topSkillId:(topSkill==null|| topSkill=="")?null:topSkill
            , secondarySkillId:(secondarySkill==null|| secondarySkill=="")?null:secondarySkill
            , workExpId:(experience==null|| experience=="")?null:experience
            ,workauthorizationId:(workStatus==null|| workStatus=="")?null:workStatus
        };
        apiClient.getCandidates(data).then(res => {
            let carr = [...candidates];
            carr = carr.concat(res);
            setCandidates(carr);
            setPageIndex(pageIndex + 1);
            setLoading(false);
            setHasMore(res.length === 20);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    };

    const searchCandidates = () => {
        setPageIndex(1);
        setCandidates([]);
        setLoading(true);
        let data: any = {
            UserID: uid, RoleTypeID: roletypeid, FilterCondition: filterCondition
            , StartIndex: 1
            , PageSize: pageSize
            , cityId:(location==null|| location=="")?null:location
            , topSkillId:(topSkill==null|| topSkill=="")?null:topSkill
            , secondarySkillId:(secondarySkill==null|| secondarySkill=="")?null:secondarySkill
            , workExpId:(experience==null|| experience=="")?null:experience
            ,workauthorizationId:(workStatus==null|| workStatus=="")?null:workStatus
            ,stateId:(stateId==null|| stateId=="")?null:stateId
            ,jobTitleId:(jobTitleId==null|| jobTitleId=="")?null:jobTitleId
        };
        apiClient.getCandidates(data).then(res => {
            setCandidates(res);
            setPageIndex(2);
            setLoading(false);
            setHasMore(res.length === 20);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    };
    const handleViewProfile = (id) => {

        setIsOpen(true);
        setSelectedRecord(id)
    };
    const selectAll = (event) => {


        const updatedCandidates = candidates.map(candidate => ({
            ...candidate
            ,
            selected: event.target.checked// Toggle the selected state based on the current selectAll state
        }));
        setCandidates(updatedCandidates);
        //setSelectAll(!selectAll);
    };

    const selectOne = (index) => {
        const updatedCandidates = candidates.map((candidate: any, i) =>
            i === index ? { ...candidate, selected: !candidate.selected } : candidate
        );
        setCandidates(updatedCandidates);

        // Check if all candidates are now selected, and update the selectAll state accordingly
        const allSelected = updatedCandidates.every(candidate => candidate.selected);

    };
    const handleClose = () => {
        setIsOpen(false);
        setIsOpenEmail(false);

    };

    const handleChange=(e,identifier)=>
    {
        if(identifier=="workauthorizationId")
        {
            debugger
            if (e === null) 
            {
                
                setWorkStatus(null);
            }
            else
            {
                setWorkStatus(e);
            }
        }
        
    };
    const handleSendEmail = () => {
        debugger
        const selectedIds = candidates
            .filter(candidate => candidate.selected)
            .map((candidate: any) => candidate.ID)
            .join(', ');
        const selectedEmailIds = candidates
            .filter(candidate => candidate.selected)
            .map((candidate: any) => candidate.EmailId)
            .join('; ');
        let data = { ids: selectedIds, emails: selectedEmailIds }
        setSelectedRecord({ data });
        setIsOpenEmail(true);
    };
    const handleExport = () => {
        const selectedIds = candidates
            .filter(candidate => candidate.selected)
            .map((candidate: any) => candidate.ID)
            .join(', ');
        let data = { ids: selectedIds }
        apiClient.loadReport("/candidates/getCandidatesListByIDS", data).then(res => {
            console.log(res);
            setDB(res);
            if (res.length > 0) {
                //let keys = Object.keys(res[0]);
                //  setCols(keys);
                exportToCSV(res);
            }


        }).catch(err => {
            console.log(err);
            setLoading(false);
        });

    };

    // useEffect(() => {
    //     if (workStatus || location || topSkill || secondarySkill || experience) {
    //         setShowAdvancedFilters(true);
    //     } else {
    //         setShowAdvancedFilters(false);
    //     }
    // }, [workStatus, location, topSkill, secondarySkill, experience]);

    function exportToCSV(data) {

        const fileName = "candidate";
        const exportType = 'xls'
        exportFromJSON({ data, fileName, exportType })

    }
    const hasSelectedCandidates = candidates.some(candidate => candidate.selected);
    return (
        <React.Fragment>


            <SimpleForm
                toolbar={null} variant="outlined" >

                <div style={{ width: "100%" }}>
                    <div className="report-heading">
                        {props.rptTtile}
                    </div>

                    <div>


                        {showAdvancedFilters && (
                            <div className="advanced-filters">
    
                                <div className={classes.one_5_input} >

                                    {<ReferenceInput perPage={25}
                                        variant="outlined" label="Work Authorization"
                                        source="workauthorizationId" reference="workauthorizations" fullWidth={true}
                                        sort={{ field: 'name', order: 'asc' }}
                                        filterToQuery={searchText => ({ 'name~like': searchText })} 
                                        filter={{ type: 'WKSET' }}
                                        // onChange={(e) => {if(e) {setWorkStatus(e);}else{setWorkStatus(null);}}} 
                                        onChange={(e)=>{handleChange(e,"workauthorizationId")}}
                                         formClassName={classes.one_5_input} allowEmpty >
                                        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }} />
                                    </ReferenceInput>}

                                </div>
                                <div className={classes.two_5_input} >
                                    {<ReferenceInput perPage={25} variant="outlined" label="Current State " source="currentStateId" reference="states" fullWidth={true} sort={{ field: 'name', order: 'ASC' }} 
                                    filterToQuery={searchText => ({ 'name~like': searchText })} 
                                    
                                    onChange={(e) => {if(e==null) {setStateId("");}else{setStateId(e);}}}
                                     initialValue={location} formClassName={classes.two_5_input}  allowEmpty>
                                        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }} />
                                    </ReferenceInput>}
                                </div>

                                <div className={classes.two_5_input} >
                                    {<ReferenceInput perPage={25} variant="outlined" label="Location " source="currentLocationStateId" reference="cities" fullWidth={true} sort={{ field: 'name', order: 'ASC' }} 
                                    filterToQuery={searchText => ({ 'name~like': searchText })} 
                                    
                                    onChange={(e) => {if(e==null) {setLocation("");}else{setLocation(e);}}}
                                     initialValue={location} formClassName={classes.two_5_input}  allowEmpty>
                                        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }} />
                                    </ReferenceInput>}
                                </div>

                                <div className={classes.three_5_input} >
                                    <ReferenceInput perPage={25} variant="outlined" label="Top Skill"
                                        source="topSkillId" reference="skills" fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }}  
                                        filterToQuery={searchText => ({ 'name~like': searchText })} 
                                        onChange={(e) => {if(e==null) {setTopSkill("");}else{setTopSkill(e);}}}
                                        allowEmpty
                                    >
                                        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }} emptyValue={''} />
                                    </ReferenceInput>
                                </div>

                                <div className={classes.four_5_input} >
                                    <ReferenceInput perPage={25} variant="outlined" label="Secondary Skill"
                                        source="secondarySkillId" reference="skills" fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} 
                                        filterToQuery={searchText => ({ 'name~like': searchText })} 
                                        onChange={(e) => {if(e==null) {setSecondarySkill("");}else{setSecondarySkill(e);}}}
                                        allowEmpty
                                    >
                                        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }} emptyValue={''} />
                                    </ReferenceInput>
                                </div>
                                <div></div>
                                <div className={classes.one_5_input} >

                                    <ReferenceInput perPage={25} variant="outlined" label="Work Experience" source="workExperienceId" reference="experiences" fullWidth={true} formClassName={classes.one_three_input} sort={{ field: 'name', order: 'ASC' }}
                                        filterToQuery={searchText => ({ 'name~like': searchText })} 
                                        onChange={(e) => {if(e==null) {setExperience("");}else{setExperience(e);}}}
                                        allowEmpty
                                    >
                                        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }} emptyValue={''} />
                                    </ReferenceInput>
                                </div>

                                <div className={classes.two_5_input} >
 
                                    <ReferenceInput perPage={25} variant="outlined" label="Job Titles" source="jobTitleId" reference="job-titles" fullWidth={true} formClassName={classes.one_three_input} sort={{ field: 'name', order: 'ASC' }}
                                        filterToQuery={searchText => ({ 'name~like': searchText })} 
                                        onChange={(e) => {if(e==null) {setJobTitleId("");}else{setJobTitleId(e);}}}
                                        allowEmpty
                                    >
                                        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }} emptyValue={''} />
                                    </ReferenceInput>
                                </div>


                            </div>
                        )}

                        <div className="candidate-search-parent">
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <div style={{ width: "50%", marginLeft: 0 }}>
                                    <TextField
                                        label="Search"
                                        value={filterCondition}
                                        onChange={(event) => setFilterCondition(event.target.value)}
                                        variant="outlined"
                                        style={{ width: 350 }}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', minWidth: "30%", maxWidth: "30%", marginLeft: 10, textAlign: 'start', alignItems: 'start' }}>
                                    <Button variant="outlined" onClick={() => searchCandidates()} color="primary" style={{ height: 35, minWidth: 120 }}>
                                        Search
                                    </Button>
                                    <Button color="primary" variant="outlined" onClick={() => setShowAdvancedFilters(!showAdvancedFilters)} style={{ height: 35, minWidth: 150 }}>
                                        {showAdvancedFilters ? "Hide Filters" : " Filters"}
                                    </Button>
                                    {hasSelectedCandidates && <Button color="primary" variant="outlined" onClick={() => handleExport()} style={{ height: 35, minWidth: 120 }}>
                                        {"Export"}
                                    </Button>
                                    }
                                    {hasSelectedCandidates && <Button color="primary" variant="outlined" onClick={() => handleSendEmail()} style={{ height: 35, minWidth: 130 }}>
                                        {"Send Email"}
                                    </Button>
                                    }
                                </div>
                            </div>

                        </div>

                        <LatestCandidates candidates={candidates} viewProfile={handleViewProfile} selectAll={selectAll} selectOne={selectOne} />
                        {!loading && hasMore && (
                            <div className="load-more-btn" onClick={() => loadMoreCandidates()}>
                                Load More
                            </div>
                        )}
                    </div>
                </div>
            </SimpleForm>
            <Drawer
                anchor="right"
                onClose={handleClose}
                classes={{ paper: myclasses.drawerPaper1500 }}
                open={isOpen || isOpenEmail}     >
                {isOpen && <CandidateView
                    onClose={handleClose}
                    record={selectedRecord}
                    {...props}
                />}
                {isOpenEmail && <CandidateEmail
                    onClose={handleClose}
                    record={selectedRecord}
                    {...props}
                />}

            </Drawer>
        </React.Fragment>

    );
};
