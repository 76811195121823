import React, { useEffect, useState } from "react";
import {
    useRefresh,
    required,
    useNotify,
    useRedirect,
    minLength,
    maxLength,
    minValue,
    ReferenceField,
    TextField,
    DateField,
    NumberField,
    ReferenceManyField,
    Datagrid,
    FormTab,
    AutocompleteInput,
    Edit, TabbedForm, TextInput, SelectInput, BooleanField,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, useQueryWithStore, FormDataConsumer,
    List, Filter
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../common/formStyles";
import { Authorizer } from "../../common/Authorizer";
import RichTextInput from 'ra-input-rich-text';
import VSDeleteButton from "../../common/CDeleteButton";
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import StageEdit from "./StageEdit";
import StageCreate from "./StageCreate";
import { Drawer } from '@material-ui/core';
import { SkillCreate } from "./SkillsCreate";
import SkillEdit from "./SkillEdit";
import RecruiterCreate from "./RecruiterCreate";
import InterviewerCreate from "./InterviewerCreate";
import { EmployeeFields } from "./EmployeeFields";

const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: "90%"
    },
}));
export const ActivityEdit = (props) => {
    const classes = useStyles({});
    const myclasses = myStyles({});
    const refresh = useRefresh();
    const [isAddStage, setIsAddStage] = useState(false);
    const [isEditStage, setIsEditStage] = useState(false);
    const [isAddRecruiter, setIsAddRecruiter] = useState(false);
    const [isAddInterviewer, setIsAddInterviewer] = useState(false);
    const [isAddCandidate, setIsAddCandidate] = useState(false);
    const [isAddSkill, setIsAddSkill] = useState(false);
    const [isEditSkill, setIsEditSkill] = useState(false);
    const [IsEditRecord, setIsEditRecord] = useState(null);
    const [locationCityId, setLocationCityId] = useState(0);
    const [workAuthorizationId, setWorkAuthorizationId] = useState(0);
    //let propsObj = Authorizer(props,"Edit"); 
    const onCancel = () => {
        setIsAddStage(false);
        setIsEditStage(false);
        setIsAddSkill(false);
        setIsEditSkill(false);
        setIsAddRecruiter(false);
        setIsAddInterviewer(false);
        setIsAddCandidate(false);
        refresh();

    }
    const onCloseForm = () => {

        setIsAddStage(false);
        setIsEditStage(false);
        setIsAddSkill(false);
        setIsEditSkill(false);
        setIsAddRecruiter(false);
        setIsAddInterviewer(false);
        setIsAddCandidate(false);
        refresh();
        //loadNotificationDetail();
    }

    let propsObj = { ...props };
    if (propsObj.permissions) {
        let resourcename = `/${props.resource}`;
        let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
        if (myPermissions.length > 0) {
            let p = myPermissions[0];
            propsObj.hasList = (p.View == 1) ? true : false;
            propsObj.hasShow = (p.View == 1) ? true : false;
            propsObj.hasEdit = (p.Edit == 1) ? true : false;
            propsObj.hasDelete = (p.Delete == 1) ? true : false;
            propsObj.hasCreate = (p.Create == 1) ? true : false;
            propsObj.hasExport = (p.Export == 1) ? true : false;
            propsObj = { ...propsObj, ...p }
        }
    }
    console.log("Permission_app", JSON.stringify(propsObj))
    const ModuleFilter = (props) => (

        <Filter {...props}>
            <ReferenceInput alwaysOn label="Search Candidate " variant="outlined" source="candidateId"
                reference="vw-requirements-candidates"
                filter={{ requirementId: props.requirementId }}
                sort={{ field: 'name', order: 'asc' }} fullWidth={true}
                filterToQuery={searchText => ({ 'name~like': searchText })}
            >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }} />
            </ReferenceInput>
        </Filter>
    );
    const CustomEdit = (props) => {
        let { record } = props;
        return <Button
            color="secondary" disableElevation
            size="medium"

            onClick={() => {
                props.onClick(record);
            }}
            startIcon={<EditIcon />}>Edit</Button>
    }
    return (
        <>
            <Edit  {...propsObj}>

                <TabbedForm variant="outlined" toolbar={<FormToolbar {...props} mode={"Edit"} />}>
                    <FormTab value="DETAILS" label="Detail">
                        <ReferenceInput label="Client" source="clientId"
                            reference="clients"
                            validate={required()} sort={{ field: 'name', order: 'asc' }} filter={{ isActive: 1 }} fullWidth={true}   >
                            <AutocompleteInput variant="outlined" optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>
                        <DateInput source="activeDate" fullWidth={true} label="Active Date" validate={[required()]} formClassName={classes.first_inline_input} />
                        <TextInput source="subjectLine" fullWidth={true} label="Subject Line" validate={[required()]} formClassName={classes.last_inline_input} />

                        <ReferenceInput perPage={25} sort={{ field: 'Name', order: 'ASC' }}
                            filterToQuery={searchText => ({ 'name~like': searchText })} variant="outlined"
                            label="Location City" source="locationCityId" reference="cities" fullWidth={true} formClassName={classes.one_three_input}  >
                            <AutocompleteInput variant="outlined" optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>
                        <BooleanInput source="isLocalOnly" validate={[required()]} initialValue={true} label="Is Local Only" formClassName={classes.two_three_input} />
                        <ReferenceInput label="Employment Type" source="employmentTypeId"
                            reference="lookups"
                            validate={required()} sort={{ field: 'name', order: 'asc' }} filter={{ type: 'EMPTP' }} fullWidth={true} formClassName={classes.last_three_input}  >
                            <AutocompleteInput variant="outlined" optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>
                        <RichTextInput fullWidth={true} validate={required()} source="jobDetails" label="Job Details" toolbar={[[{ 'header': [1, 2, 3, false] }], ['bold', 'italic', 'underline', 'strike', 'blockquote'], [{ 'list': 'ordered' }, { 'list': 'bullet' }], ['image', 'link', 'video'], ['clean']]} formClassName={classes.first_inline_input} />
                        <RichTextInput fullWidth={true} validate={required()} source="skilDetails" label="Skill Details" toolbar={[[{ 'header': [1, 2, 3, false] }], ['bold', 'italic', 'underline', 'strike', 'blockquote'], [{ 'list': 'ordered' }, { 'list': 'bullet' }], ['image', 'link', 'video'], ['clean']]} formClassName={classes.last_inline_input} />
                        <ReferenceInput perPage={25} sort={{ field: 'Name', order: 'ASC' }}
                            filterToQuery={searchText => ({ 'name~like': searchText })} variant="outlined"
                            label="Workauthorization" source="workAuthorizationId" reference="workauthorizations" fullWidth={true} formClassName={classes.first_inline_input}  >
                            <AutocompleteInput variant="outlined" optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>

                        <ReferenceInput perPage={25} sort={{ field: 'userName', order: 'ASC' }}
                            filterToQuery={searchText => ({ 'userName~like': searchText })} variant="outlined"
                            label="Assigned By" source="assignedBy" reference="users" fullWidth={true} formClassName={classes.last_inline_input}  >
                            <AutocompleteInput variant="outlined" optionText="userName" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>
                        <ReferenceInput perPage={25} sort={{ field: 'userName', order: 'ASC' }}
                            filterToQuery={searchText => ({ 'userName~like': searchText })} variant="outlined"
                            label="Assigned To" source="assignedTo" reference="users" fullWidth={true} formClassName={classes.first_inline_input}  >
                            <AutocompleteInput variant="outlined" optionText="userName" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>

                        <ReferenceInput label="Status" source="statusId"
                            reference="lookups"
                            validate={required()} sort={{ field: 'name', order: 'asc' }} filter={{ type: 'REQUISSTATUS' }} fullWidth={true} formClassName={classes.last_inline_input}  >
                            <AutocompleteInput variant="outlined" optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>
                    </FormTab>
                   {propsObj.hasViewSelectionProcess && <FormTab value="Other" label="Selection Process">
                        {/* <Button variant="contained" onClick={() => setIsAddStage(true)}
                            color="secondary">
                            Add Process

                        </Button> */}
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="requirements-stages"
                            target="requirementId">

                            <Datagrid >
                            {propsObj.hasEditSelectionProcess &&  <VSDeleteButton {...props} />}
                              {propsObj.hasEditSelectionProcess &&  <CustomEdit onClick={(record) => {
                                    setIsEditRecord(record); setIsEditStage(true);
                                }} label="Edit" />}

                                <ReferenceField source="selectionStageId" reference="selection-stages" label="Selection Stage" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField source="selectionStageId" reference="selection-stages" label="Type" link={false}>
                                    <TextField source="type" />
                                </ReferenceField>
                                <ReferenceField source="sequenceId" reference="lookups" label="Selection Sequence" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <BooleanField source="isMailTrigger" label="Mail Trigger" />
                                <BooleanField source="isApplicableForInterviewer" label="Is Applicable For Interviewer" />
                                <BooleanField source="isAssignedToRecruiter" label="Is Applicable For Recruiter" />

                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab>}
                  {propsObj.hasViewSkills &&  <FormTab value="Other" label="Skills">
                       {propsObj.hasCreateSkills && <Button variant="contained" onClick={() => setIsAddSkill(true)}
                            color="secondary">
                            Add Skill

                        </Button>}
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="requirements-skills-experiences"
                            target="requirementsId">

                            <Datagrid >
                                {propsObj.hasDeleteSkills &&  <VSDeleteButton {...props} />}
                               {propsObj.hasEditSkills && <CustomEdit onClick={(props) => {

                                    setIsEditRecord(props); setIsEditSkill(true);
                                }} label="Edit" />}

                                <ReferenceField source="skillId" reference="skills" label="Skill" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField source="experienceId" reference="experiences" label="Experiences" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>

                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab>}
                  {propsObj.hasViewRecruiters &&  <FormTab value="Other" label="Recruiters">
                        { propsObj.hasCreateRecruiters && <Button variant="contained" onClick={() => setIsAddRecruiter(true)}
                            color="secondary">
                            Add Recruiter
                        </Button>}
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="requirements-recruters"
                            target="requirementId">
                            <Datagrid >
                              { propsObj.hasDeleteRecruiters &&  <VSDeleteButton {...props} />}
                                <ReferenceField source="recruiterId" reference="users" label="Recruiter" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <TextField source="remarks" label="Remarks" />


                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab>}

                   {propsObj.hasViewInterviewers && <FormTab value="Other" label="Interviewers">
                       {propsObj.hasCreateInterviewers && <Button variant="contained" onClick={() => setIsAddInterviewer(true)}
                            color="secondary">
                            Add Interviewer
                        </Button>}
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="requirements-interviewers"
                            target="requirementId">
                            <Datagrid >
                               {propsObj.hasDeleteInterviewers && <VSDeleteButton {...props} />}
                                <ReferenceField source="interviewerId" reference="users" label="Interviewer" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <TextField source="remarks" label="Remarks" />


                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab>}
                  {propsObj.hasViewCandidates &&  <FormTab value="Other" label="Candidate">
                        {/* <Button variant="contained"
                         onClick={() => setIsAddCandidate(true)}
                            color="secondary">
                            Add Candidate
                        </Button> */}
                        <ReferenceManyField perPage={10000} reference="requirements-details" target="requirementId" addLabel={false} fullWidth={true}>
                            <List {...props}
                                title=" "
                                perPage={10000}
                                pagination={null}
                                bulkActionButtons={false}
                                exporter={false}
                                hasCreate={false}
                                reference="requirements-details"
                                filter={propsObj.hasViewOwnCandidates?{ requirementId: props.id }:{ requirementId: props.id }}
                                filters={<ModuleFilter nameLabel={"Candidate "} requirementId={props.id} />}
                            >
                                <Datagrid rowClick={false} id={"ABC"}>
                                {propsObj.hasDeleteCandidates &&  <VSDeleteButton {...props} />}
                                   <ReferenceField source="candidateId" reference="candidates" label="Candidate" link={false}>
                                                                           <TextField source="name" />
                                                                       </ReferenceField>
                                                                       <ReferenceField source="candidateId" reference="candidates" label="Email" link={false} >
                                                                           <TextField source="personnelEmailId" />
                                                                       </ReferenceField>
                                                                       <ReferenceField source="candidateId" reference="candidates" label="Contact No" link={false} >
                                                                           <TextField source="contactNo" />
                                                                       </ReferenceField>
                                                                       <ReferenceField source="candidateId" reference="candidates" label="SSNNO" link={false} >
                                                                           <TextField source="ssnno" />
                                                                       </ReferenceField>
                                                                       
                                   
                                                                       <ReferenceField source="currentStageId" reference="selection-stages" label="Current Stage" link={false}>
                                                                           <TextField source="name" />
                                                                       </ReferenceField>
                                                                       <ReferenceField source="statusId" reference="lookups" label="Status" link={false}>
                                                                           <TextField source="name" />
                                                                       </ReferenceField>
                                                                       <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                                                           <TextField source="userName" />
                                                                       </ReferenceField>
                                </Datagrid>
                            </List>

                        </ReferenceManyField>
                    </FormTab>}

                </TabbedForm>
            </Edit>
            <Drawer anchor="right" onClose={onCancel} classes={{
                paper: myclasses.drawerPaper,
            }}
                open={isAddStage || isEditStage || isAddSkill || isEditSkill || isAddRecruiter || isAddInterviewer || isAddCandidate}>


                {isAddStage &&
                    <StageCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        requirementId={props.id}
                        subjectId={0}
                        {...props} />}

                {isEditStage && <StageEdit
                    onClose={onCloseForm}
                    onCancel={onCancel}
                    record={IsEditRecord}
                    subjectId={0}
                    {...props} />}

                {isAddSkill &&
                    <SkillCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        requirementId={props.id}
                        subjectId={0}
                        {...props} />}

                {isEditSkill && <SkillEdit
                    onClose={onCloseForm}
                    onCancel={onCancel}
                    record={IsEditRecord}
                    subjectId={0}
                    {...props} />}
                {isAddCandidate &&
                    <EmployeeFields
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        requirementId={props.id}
                        locationCityId={locationCityId}
                        workAuthorizationId={workAuthorizationId}

                        {...props} />}


                {isAddRecruiter &&
                    <RecruiterCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        requirementId={props.id}
                        subjectId={0}
                        {...props} />}


                {isAddInterviewer &&
                    <InterviewerCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        requirementId={props.id}
                        subjectId={0}
                        {...props} />}



            </Drawer>

        </>
    );
};
