
import React, { forwardRef, useState, useEffect } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import * as apiClient from '../common/apiClient';

import Logo from './Logo';
import { ROLETYPE_AUDIT, ROLETYPE_INBOUND, ROLETYPE_PACKER, ROLETYPE_PICKER, ROLETYPE_VNA } from '../common/constants';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    version: {
        marginLeft: '20px',
        flex: 1,
        fontWeight: 'bold',
        color: '#efefef'
    },
    username: {
        alignSelf: "center",
    },
});

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={translate('pos.configuration')}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
        />
    );
});

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>
        {/* <ConfigurationMenu /> */}
    </UserMenu>
);

const isStationUser = (roleType) => {
    if (roleType == ROLETYPE_PICKER || roleType == ROLETYPE_PACKER
        || roleType == ROLETYPE_VNA || roleType == ROLETYPE_INBOUND || roleType == ROLETYPE_AUDIT) {
        return true;
    }
    return false;
}

const CustomAppBar = (props: any) => {
    const classes = useStyles({});
    
    const un = localStorage.getItem("firstname") + " " + localStorage.getItem("lastname") +"( "+localStorage.getItem("RoleTypeName")+" )";
    
    const RoleType = localStorage.getItem("RoleType");
    

    let userid = localStorage.getItem("userId");
    let stationId = localStorage.getItem("StationId");
 
     

    return (
        <AppBar {...props} userMenu={<CustomUserMenu />}>
            <Logo />
            <span className={classes.version}> AppVersion 1.0.0</span>

            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            
            <span className={classes.username}> {un} </span>
            
            {isStationUser(RoleType) && <span className="station-name"> </span>}
        </AppBar>
    );
};

export default CustomAppBar;
