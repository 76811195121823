import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import {ChangePassword} from "./screens/changepassword/ChangePassword";
import { CandidateQuery } from './screens/reports/CandidateQuery';
import { ModuleExport } from './screens/reports/ModuleExport';
import { MasterReport } from './screens/reports/MasterReport';


export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,   
    <Route exact path="/changepassword" render={(props) => <ChangePassword {...props}/>}  />,
    <Route exact path="/candidate-queries" render={(props) => <CandidateQuery rptTtile="Candidate Query" rpt="HRC" {...props} />} />,
    <Route exact path="/repoexport" render={(props) => <ModuleExport rptTtile="Module Export" rpt="ModuleExport" {...props} />} />,
    <Route exact path="/requirement-queries" render={(props) => <MasterReport rptTtile="Requirement Queries" rpt="REQQUERY" {...props} />} />,
    <Route exact path="/submission-queries" render={(props) => <MasterReport rptTtile="Submission Queries" rpt="SUBQUERY" {...props} />} />,
    <Route exact path="/interview-schedules" render={(props) => <MasterReport rptTtile="Interview schedules" rpt="INTNSCHED" {...props} />} />,
    <Route exact path="/po-queries" render={(props) => <MasterReport rptTtile="PO Queries" rpt="POQUERY" {...props} />} />,
    
    
];
