import React from 'react';
import Card from '@material-ui/core/Card';
import DollarIcon from '@material-ui/icons/AttachMoney';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';


import { useTranslate } from 'react-admin';
import CommentIcon from '@material-ui/icons/Comment';

import CardIcon from './CardIcon';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
        width: 330,
        marginBottom: 15,
        position: "relative"
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
    img: {
        height: 83,
        position: "absolute",
        left: 2,
        top: 2,
    }
});

const CountBlock = ({ title, value, icon, ParamCode, on_Click }) => {
    const translate = useTranslate();
    const classes = useStyles({});
    return (
        <div className={classes.main}>
            {!icon && <CardIcon Icon={CommentIcon} bgColor="#f44336" />}
            {icon && icon != "" && <img className={classes.img} src={icon} bgColor="#f44336" />}
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    {title}
                </Typography>
                <Link href="#" onClick={(e) => { e.preventDefault(); on_Click(ParamCode); }}>
                    <Typography variant="h5" component="h2">
                        {value}
                    </Typography>
                </Link>

            </Card>
        </div>
    );
};

export default CountBlock;
