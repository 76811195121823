import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";
import { DisplayDateTime } from "../../common/DisplayDate";
import { Authorizer } from "../../common/Authorizer";


const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="Subject Line" source="subjectLine~like" alwaysOn />
    </Filter>
);
export const ActivityList = (props) => {
  let propsObj = Authorizer(props,"List"); 
  return (
    <List  {...propsObj}  exporter={false} bulkActionButtons={false}     filters={<Filters />}  >
      <Datagrid rowClick={false} > 
        {propsObj.hasEdit && <EditButton />} 
        
        <TextField source="requisitionNo" label="Requisition No" />
        <TextField source="subjectLine" label="Subject Line" />
        <DateField  source="activeDate" label="Open Date" locales="fr-FR" />
        <ReferenceField source="statusId" reference="lookups" label="Status"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="assignedBy" reference="users" label="Assigned By"  link={false}>
                <TextField source="name" />
            </ReferenceField> 
            <ReferenceField source="assignedTo" reference="users" label="Assigned To"  link={false}>
                <TextField source="name" />
            </ReferenceField> 
            <ReferenceField source="clientId" reference="clients" label="Client"  link={false}>
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="isActive" label="Active"/> 
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
