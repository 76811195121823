import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as apiClient from "../common/apiClient";
import CountBlock from "./CountBlock";
import moment from "moment";
import exportFromJSON from 'export-from-json'
const useStyles = makeStyles({
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexWrap: "wrap",
  },
  dateRangeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    marginTop: "20px",
    borderBottom:"1px solid lightgrey",
    paddingBottom:"30px"
  },
  dateField: {
    marginRight: "10px",
    padding: "10px",
    fontSize: "16px",
    width: "200px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
});

const Dashboard = () => {
  const styles = useStyles();  
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().add(7, "days").format("YYYY-MM-DD"));
  const [db, setDB] = useState([]);
  const [dbExport, setdbExport] = useState([]); 
  const getDashboard = (fromDate, toDate) => {
    const payload = {
      FromDate: fromDate,
      ToDate: toDate,
      RptType: "Summary",
    };
    apiClient
      .get_dashboardV1(payload)
      .then((res) => {
        setDB(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleLoadData = () => {
    getDashboard(fromDate, toDate);
  };

  function exportToCSV(data) {
    const fileName = 'download'
    const exportType = 'xls'
    exportFromJSON({ data, fileName, exportType })

  }
const onExport = (ParamCode) => {

    let userid = localStorage.getItem("userId");
    setdbExport([]); 
    const payload = {
        FromDate: fromDate,
        ToDate: toDate,
        RptType: "detailed",
        ParamCode:ParamCode
      };
      apiClient
        .get_dashboardV1(payload)
        .then((res) => {
          
          exportToCSV(res);
        })
        .catch((err) => {
          console.error(err);
        });


    //alert(Key)
}
  useEffect(() => {
    if (fromDate && toDate) {
      handleLoadData();
    }
  }, [fromDate, toDate]);

  return (
    <div>
      {/* Date Range Inputs */}
      <div className={styles.dateRangeContainer}>
        <div>
            From Date:{ " "}
        <input
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          className={styles.dateField}
        /></div>
        <div>
            To Date:{ " "}
        <input
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          className={styles.dateField}
        /></div>
        <Button variant="contained" color="primary" onClick={handleLoadData}>
          Load Data
        </Button>
      </div>

      {/* Dashboard Content */}
      <div className={styles.flexCenter}>
        {db.map((item: any, index) => (
          <div key={index}>
            <CountBlock
              title={item.DashboardType}
              value={item.TotalCount}
              icon={item.IconUrl}
              ParamCode={item.ParamCode}
              on_Click={(paramCode) => {
                onExport(paramCode)
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
