import React from 'react';
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices, Create, SimpleForm, TextInput, DateInput,
    ImageInput, ImageField, ReferenceInput, SelectInput, FormDataConsumer,
    BooleanInput, NumberInput, FileInput, FileField, AutocompleteInput
} from 'react-admin';
import { useStyles } from "../../formStyles";
import { AddressFields } from "./AddressFields";

export const CandidateCreate = props => {
    
    const classes = useStyles({});
    const initialValues = {
        isActive: true,
        activeDate: new Date()

    }
    const filterToQuery = (searchText) => ({
        description: { like: searchText, options: 'i' },
    });
    return (
        <Create {...props} redirect="list">
            <SimpleForm initialValues={initialValues} variant="outlined">
                <DateInput source="activeDate" fullWidth={true} label="Active Date" validate={[required()]} formClassName={classes.first_inline_input} />
                <TextInput source="name" fullWidth={true} label="Name" validate={[required()]} formClassName={classes.last_inline_input} />


                <TextInput source="address1" label="Address Line 1" fullWidth={true} multiline  formClassName={classes.first_inline_input} />
                <TextInput source="address2" label="Address Line 2" fullWidth={true} multiline formClassName={classes.last_inline_input} />
                <FormDataConsumer fullWidth={true}>
                    {formDataProps => (
                        <AddressFields {...formDataProps}

                            showAsAbove={true}
                        />
                    )}
                </FormDataConsumer>
                <TextInput source="personnelEmailId" label="Personal Email " fullWidth={true} validate={[required(), email()]} formClassName={classes.one_three_input} />
                <TextInput source="alternateEmailId" label="Alternate Email " fullWidth={true}  formClassName={classes.two_three_input} />
                <TextInput source="contactNo" label="Contact No " fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />

                <TextInput source="alternateContactNo" label="Alternate Contact No" fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="ssnno" label="SSNNO " fullWidth={true} formClassName={classes.two_three_input} />
                <div></div>


                <ReferenceInput perPage={25} variant="outlined" label="Highest Qualification " source="highestQualificationId" reference="qualifications" fullWidth={true} formClassName={classes.one_three_input}  sort={{ field: 'name', order: 'ASC' }} >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput>

{/* <ReferenceInput perPage={25} variant="outlined" label="Current Location State " source="currentLocationStateId" reference="states" fullWidth={true} formClassName={classes.two_three_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput> */}
                 <TextInput source="linkedInId" label="LinkedIn Id" fullWidth={true}  formClassName={classes.two_three_input} />

              
                <BooleanInput source="relocation" label="Relocation" fullWidth={true} formClassName={classes.last_three_input} />


                <ReferenceInput filterToQuery={searchText => ({ 'name~like': searchText })} perPage={25} variant="outlined" label="Work Authorization" source="workauthorizationId" reference="workauthorizations" fullWidth={true} formClassName={classes.one_three_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput>

                <ReferenceInput perPage={25000} filterToQuery={searchText => ({ 'name~like': searchText })} variant="outlined" label="Job Title " source="preferredJobTitleId" reference="job-titles" fullWidth={true} formClassName={classes.two_three_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput>
                {/* 4 */}
                <FormDataConsumer fullWidth={true} formClassName={classes.last_three_input}>
                    {({ formData, ...rest }) =>
                    
                        (formData.workauthorizationId &&  formData.workauthorizationId === 4) ? (
                            <ReferenceInput perPage={25} variant="outlined" label="Current Employment " source="currentEmploymentId"
                                reference="agents"
                                fullWidth={true} formClassName={classes.last_three_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
                                <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => val && val.trim().length >= 1} filterToQuery={filterToQuery} emptyValue={''} />
                            </ReferenceInput>
                        ) : (
                            <ReferenceInput perPage={2500} variant="outlined" label="Current Employment" source="currentEmploymentId"
                                reference="agents"
                                filterToQuery={searchText => ({ 'name~like': searchText })}
                                fullWidth={true} formClassName={classes.last_three_input} sort={{ field: 'name', order: 'ASC' }} >
                                <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => val && val.trim().length >= 1} filterToQuery={filterToQuery} emptyValue={''} />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>
                

                <NumberInput source="salaryinUsd" label="Salary/Rate " fullWidth={true} validate={[required()]} initialValue={0} formClassName={classes.one_three_input} />
                <ReferenceInput perPage={25} variant="outlined" label="Hourly Rate" source="hourlyRateId"
                    reference="lookups"                    
                    sort={{ field: 'name', order: 'asc' }}
                    filter={{ type: 'HRLRT' }}
                    fullWidth={true} formClassName={classes.two_three_input}       >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput perPage={25} variant="outlined" label="Work Setting" source="workSettingId"
                    reference="lookups"                    
                    sort={{ field: 'name', order: 'asc' }}
                    filter={{ type: 'WKSET' }}
                    fullWidth={true} formClassName={classes.last_three_input}      >
                    <SelectInput optionText="name" />
                </ReferenceInput>
               <ReferenceInput perPage={25} variant="outlined" label="Work Experience" source="workExperienceId" reference="experiences" fullWidth={true} formClassName={classes.one_three_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput>
                <ReferenceInput perPage={25} variant="outlined" label="Top Skill" source="topSkillId" reference="skills" fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} 
                filterToQuery={searchText => ({ 'name~like': searchText })}
                >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}  />
                </ReferenceInput>

                  <ReferenceInput perPage={2500} variant="outlined" label="Preferred Job Title" source="secondarySkillId" reference="skills" fullWidth={true} formClassName={classes.last_three_input}  sort={{ field: 'name', order: 'ASC' }}
                  filterToQuery={searchText => ({ 'name~like': searchText })}
                   >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}  />
                </ReferenceInput>
                




                <BooleanInput source="isSignedPaper" label="Signed Paper" fullWidth={true} formClassName={classes.one_three_input} />

                <div>

                </div>
                {<FileInput source="resumeUrl1" formClassName={classes.first_inline_input}
                    label="Resume" className="logourl">
                    <FileField source="resumeUrl1" title="Resume" />
                </FileInput>

                }
                <FileField source="resumeUrl" label="" title="Download Resume" formClassName={classes.last_inline_input} />

                <div>


                </div>
                {<FileInput source="covernoteUrl1" formClassName={classes.first_inline_input}
                    label="Cover Note" className="logourl">
                    <FileField source="covernoteUrl1" title="Cover Note" />
                </FileInput>

                }
                <FileField source="covernoteUrl" label="" title="Download Cover Note" formClassName={classes.last_inline_input} />

                <TextInput source="remarks" label="Remarks" fullWidth={true} multiline formClassName={classes.one_three_input} />
                <TextInput source="dlStateId" label="DL/State ID" fullWidth={true}  formClassName={classes.two_three_input} />
                

            </SimpleForm>
        </Create>
    );
}